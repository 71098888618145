var arr = [
  {
    title: 'الرئيسية',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'اختيار المخططات',
    route: 'projects',
    icon: 'CheckSquareIcon',
  },
  {
    title: 'سجل الحجوزات',
    route: 'orders',
    icon: 'ClockIcon',
  },
  {
    title: 'سجل التنبيهات',
    route: 'noti',
    icon: 'BellIcon',
  },
  {
    title: 'الإعدادات',
    route: 'settings',
    icon: 'SettingsIcon',
  },
  {
    title: 'شرح الاستخدام',
    href: 'https://brmja.com/howto#sakani',
    icon: 'VideoIcon',
  },
  {
    title: 'تسجيل الخروج',
    route: 'logout',
    icon: 'LogOutIcon',
  },
]

export default arr;
